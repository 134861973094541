export default {
    MODALS_TELEPORT_TARGET: '#teleportModals',
    GRECAPTCHA_DISABLED: false,
    GRECAPTCHA_SITE_KEY: '6LftCzElAAAAAL7FwnHQCJZTQKwXkvLrGxpQbwPV',
    CANVAS_FABRIC_ID: 'fabricCanvas',
    CARD_SIZE: {
        WIDTH: 428,
        HEIGHT: 535,
    },
    CAMERA_CONSTRAINT :{
        WIDTH: 480,
        HEIGHT: 640
    }
}