<template>
    <div class="vstack justify-content-start gap-3 p-3 px-4">
        <button class="btn btn-tnb-gradient w-100 mt-3" @click="continueHandler">Selesai dan Previu</button>
        <!-- <button @click="designStore.seeCanvas"> canvas</button> -->
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { fabric } from "fabric";
import useFabricCanvas from '../../composables/use-fabric-canvas';
import { useDesignStore } from '../../stores';

const emits = defineEmits(['generate-preview']);

const defaultOptionRef = ref<HTMLElement>();
const [canvas] = useFabricCanvas();
const designStore = useDesignStore();

const defaultTextboxValue = 'Taip nama anda';
let coords = {
    x: 0,
    y: 0
};

const addTextbox = () => {

    let nameTextBox = new fabric.IText(defaultTextboxValue, {
        name: 'textbox-element',
        width: 100,
        fontSize: 16,
        fontFamily: 'Roboto',
        fontWeight: 500,
        fill: '#EDDC98',
        top: 88 * designStore.postCardDimensionSettings.height / 100,
        left: (designStore.postCardDimensionSettings.width / 2),
        textAlign: 'center',
        originX: 'center',
    });

    nameTextBox.on('mousedown', function (obj) {
        coords = canvas.getPointer(obj.e);
    });

    nameTextBox.on('mouseup', function (obj) {
        let e = canvas.getPointer(obj.e) as fabric.Point;
        if (e.x == coords.x && e.y == coords.y) {
            const objTarget = obj.target as fabric.IText;
            objTarget.enterEditing();
            // @ts-ignore
            objTarget.setCursorByClick(e);
        }
    });

    nameTextBox.on("editing:entered", function (e) {
        var obj = canvas.getActiveObject() as fabric.IText;
        if (obj.text === defaultTextboxValue) {
            obj.selectAll();
            obj.text = "";
            obj.hiddenTextarea!.value = "";
            obj.dirty = true;
            obj.setCoords();
            canvas.renderAll();
        }
    });

    nameTextBox.on("editing:exited", function (e) {
        var obj = canvas.getActiveObject() as fabric.IText;
        if (obj.text === '') {
            obj.text = defaultTextboxValue;
            obj.dirty = true;
            obj.setCoords();
            canvas.renderAll();
        }
    });

    canvas.add(nameTextBox);
}

const continueHandler = () => {
    canvas._objects.find(item => {
        if (!item) return;
        // @ts-ignore
        if (item.name === 'textbox-element' && item instanceof fabric.IText && item.text === defaultTextboxValue) {
            canvas.remove(item);
        }
    })

    canvas.discardActiveObject().renderAll();
    emits('generate-preview');
}

onMounted(() => {
    defaultOptionRef.value?.click();
    addTextbox();
})

</script>