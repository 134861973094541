<script lang="ts">
import { defineComponent, onMounted } from 'vue';

import {
} from '@/modules/core/components';

import {
    MainContainer,
    FabricCanvas,
} from './components';
import { useBreakpoints, breakpointsBootstrapV5 } from '@vueuse/core';
import { setCssHelperVars } from '@/modules/core/utilities';

export default defineComponent({
    components: {
        MainContainer,
        FabricCanvas,
    },
    setup() {
        const breakpoints = useBreakpoints(breakpointsBootstrapV5);
        const lgAndGreater = breakpoints.greater('lg');

        onMounted(() => {
            setCssHelperVars();
        })

        return {
            lgAndGreater
        }
    }
})
</script>