import { defineStore } from "pinia";
import useFabricCanvas from "../composables/use-fabric-canvas";
import { delay } from "@/modules/core/utilities";
import endpoints from "@/modules/website/endpoints";
import { useMediaRecorder } from "../composables";
import { useDesignStore } from "./design";

export interface IPreviewState {
    videoBlob: null | Blob;
    imageString: null | string;
    recordStreamCamRef: null | HTMLVideoElement;
    audioRayaTheme1Ref: null | HTMLAudioElement;
    audioRayaTheme2Ref: null | HTMLAudioElement;
    videoFileType: 'mp4' | 'webm';
    selectedRecordPeriod: number;
    selectedMediaType: 'video' | 'picture' | null,
    isRecordingWebCam: boolean,
}

export const usePreviewStore = defineStore('preview', {
    state: (): IPreviewState => ({
        videoBlob: null,
        imageString: null,
        videoFileType: 'webm',
        recordStreamCamRef: null,
        audioRayaTheme1Ref: null,
        audioRayaTheme2Ref: null,
        selectedRecordPeriod: 7,
        selectedMediaType: null,
        isRecordingWebCam: false,
    }),
    getters: {
    },
    actions: {
        mergeAudioFromVideo() {
            console.log('mergeAudioFromVideo(): starting merging audio');
            if (!this.audioRayaTheme1Ref || !this.audioRayaTheme2Ref || !this.recordStreamCamRef) return;

            const selectedTheme = useDesignStore().selectedTheme;
            const audioFromSong = selectedTheme === 2 ? this.audioRayaTheme1Ref : this.audioRayaTheme2Ref;
            const audioFromWebCam = this.recordStreamCamRef;
            const audioTouse = this.selectedMediaType === 'video' ? audioFromWebCam : audioFromSong;

            // @ts-expect-error
            let AudioContext = window.AudioContext || window.webkitAudioContext;
            if (!AudioContext) return alert("Sorry, but the Web Audio API is not supported by your browser. Please, consider upgrading to the latest version or downloading Google Chrome or Mozilla Firefox");

            const audioCtx = new AudioContext();
            const audioSourceNode = audioCtx.createMediaElementSource(audioTouse);
            const destination = audioCtx.createMediaStreamDestination();

            audioSourceNode.connect(destination);

            console.log('mergeAudioFromVideo(): completed merging audio');
            return destination.stream.getAudioTracks()[0];
        },
        async generateVideoPreviewTry2() {
            if (!this.audioRayaTheme1Ref || !this.audioRayaTheme2Ref || !this.recordStreamCamRef) return;
        
            const selectedTheme = useDesignStore().selectedTheme;
            const audioFromSong = selectedTheme === 2 ? this.audioRayaTheme1Ref : this.audioRayaTheme2Ref;
            const audioFromWebCam = this.recordStreamCamRef;
            const audioToUse = this.selectedMediaType === 'video' ? audioFromWebCam : audioFromSong;

            const audioContext = new AudioContext();
        
            // Create two MediaElementAudioSourceNodes, one for the video and one for the audio
            const audioToUseSource = audioContext.createMediaElementSource(audioToUse);
        
            // Create a GainNode to control the volume of the audio sources
            const gainNode = audioContext.createGain();
            // const gainNode2 = audioContext.createGain();
        
            // Set the gain values for the audio sources (between 0 and 1)
            gainNode.gain.value = 1; // Set the volume of the video audio to full
            // gainNode2.gain.value = 0.5; // Set the volume of the song audio to half
        
            // Create a ChannelMergerNode to merge the audio sources
            const mergerNode = audioContext.createChannelMerger(2); // Merge into a stereo output
        
            // Connect the audio sources and gain nodes to the merger node
            audioToUseSource.connect(gainNode).connect(mergerNode, 0, 0); // Connect the video audio to the left channel
        
            // Create a MediaStreamDestination node to capture the audio output
            const mediaStreamDestination = audioContext.createMediaStreamDestination();
        
            // Connect the merger node to the MediaStreamDestination node
            mergerNode.connect(mediaStreamDestination);
        
            // Create a MediaStreamAudioSourceNode from the MediaStreamDestination node
            const audioStreamSource = audioContext.createMediaStreamSource(mediaStreamDestination.stream);
        
            // Create a canvas stream
            let canvasEl = document.getElementById("fabricCanvas") as HTMLCanvasElement;
            const canvasStream = canvasEl.captureStream(60);
        
            // Add the audio context destination to the canvas stream
            canvasStream.addTrack(mediaStreamDestination.stream.getAudioTracks()[0]);
        
            const mediaRecorder = useMediaRecorder();
            mediaRecorder.loadMediaRecorder(canvasStream);
        
            this.videoFileType = mediaRecorder.isTypeSupported('video/webm') ? 'webm' : 'mp4';

            try {
                if(this.selectedMediaType !== 'video') {
                    selectedTheme === 2 ? this.audioRayaTheme1Ref?.play() : this.audioRayaTheme2Ref?.play();
                } else {
                    this.recordStreamCamRef?.play();
                }

                mediaRecorder?.start();
                await delay(7000);
                selectedTheme === 2 ? this.audioRayaTheme1Ref?.pause() : this.audioRayaTheme2Ref?.pause();

                if ((this.recordStreamCamRef as HTMLVideoElement).currentTime !== 0) {
                    this.recordStreamCamRef?.pause();
                }

                this.videoBlob = await mediaRecorder.stop();
        
                console.log('completed', this.videoBlob );
            } catch (error) {
                console.log('hidden error', error);
            }
        
        },
        async generateImagePreview() {
            console.log('generateImagePreview(): start generating preview');

            let canvasEl = document.getElementById("fabricCanvas") as HTMLCanvasElement;
            this.imageString = canvasEl.toDataURL('image/png');

            console.log('generateImagePreview(): complete generating preview');
        },
        async transcodeVideo(recaptchaResponse?: string) {
            if (!this.videoBlob) return;
            console.log('transcodeVideo(): start transcoding video');

            const startTime = performance.now(); // get start time
            let blob = new Blob([this.videoBlob]);

            let formData = new FormData();
            let file = new File([blob], 'Selamat-Hari-Raya.webm');
            formData.append('File', file, 'Selamat-Hari-Raya.webm');

            const config: RequestInit = {
                method: 'POST',
                body: formData,
            }

            if (recaptchaResponse) {
                config.headers = { 'x-g-recaptcha-response': recaptchaResponse };
            }

            const request = await fetch(endpoints.VIDEO_GENERATE, config);
            this.videoBlob = await request.blob();

            const endTime = performance.now(); // get end time
            const totalTime = endTime - startTime; // calculate total time

            console.log(`transcodeVideo(): completed transcoding video in ${totalTime} milliseconds`);
        },

    }
})
