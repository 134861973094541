import { ref } from 'vue';
import debounce from 'lodash/debounce';
import { Toast } from '@/modules/core/types';

const toasts = ref<Array<Toast>>([]);

export const useNotifications = () => {

    const showToast = (toast: Toast) => {
        toasts.value.push(toast)
    }

    const showToastDebounced = debounce((toast: Toast) => {
        showToast(toast)
    }, 200, { leading: false, trailing: true })

    const handleToastHidden = (toast: Toast) => {
        const index = toasts.value.indexOf(toast);
        if (index !== -1) {
            toasts.value.splice(index, 1);
        }
    }

    return {
        toasts,
        showToast,
        handleToastHidden,
        showToastDebounced,
    }
}