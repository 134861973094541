// These are here so that the bundler picks them up and processes them
import "@/modules/core/styles/index.scss";
import "./styles/index.scss";

//@ts-ignore
import 'virtual:svg-icons-register';

import { createApp } from 'vue';
import configureAxios from "./config/axios";
import Pinia from "./stores";
import App from './website.vue';

import * as Sentry from "@sentry/vue";

const app = createApp(App);

if (import.meta.env.MODE === 'production') {
    Sentry.init({
        app,
        dsn: "https://0736167ad5a64520922b47660a836ed3@o4504790555623424.ingest.sentry.io/4505039762161664",
        environment: import.meta.env.MODE,
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ["localhost", "https://test.e-greetingtnb.com/", "https://www.e-greetingtnb.com/", /^\//],
            }),
            new Sentry.Replay(),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.5,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

// Pinia needs the app to use as a symbol to ensure it is a singleton 
app.use(Pinia);

// As we configure our axios interceptor using values from Pinia, we invoke it after the line above
configureAxios();

app.mount('#app');