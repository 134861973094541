import dayjs from 'dayjs';
import local from 'dayjs/plugin/utc';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(local);
dayjs.extend(AdvancedFormat);

export function formatDateAsString (date: string|Date|undefined|null, formattingToken: string, isUTC = true) {
    if (isUTC) {
        if (typeof date === 'string') {
            if (!date.endsWith('Z')) {
                date = date + 'Z';
            }
        }
        return dayjs(date).local().format(formattingToken)
    }
    return dayjs(date).format(formattingToken)
}
