<template>
    <DesignAddMediaPhoto v-if="selectedMediaType === 'picture'" @completed="emits('next')"></DesignAddMediaPhoto>
    <DesignAddMediaVideo v-else-if="selectedMediaType === 'video'" @completed="emits('next')"></DesignAddMediaVideo>
    <div v-else class="p-3 px-4 flex-grow-1">
        <div class="vstack gap-4">
            <div class="hstack gap-3">
                <button class="btn btn-outline-primary rounded-pill w-100" @click="selectMediaType('picture')">
                    Gambar
                </button>
                <button class="btn btn-outline-primary rounded-pill w-100" @click="selectMediaType('video')">
                    Video
                </button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { usePreviewStore } from '../../stores';
import DesignAddMediaPhoto from './design-add-media-photo.vue';
import DesignAddMediaVideo from './design-add-media-video.vue';

const emits = defineEmits(['next']);

const previewStore = usePreviewStore();
const { selectedMediaType } = storeToRefs(previewStore);

const selectMediaType = (type: 'video' | 'picture') => {
    selectedMediaType.value = type;
    console.log('selectMediaType(): selected media type ', type);
}

</script>

<style lang="scss">
.btn.btn-radio-select-media {
    border-color: $white;
    transition: border-color 0.2s ease-in-out 0s;

    &.active {
        border-color: $primary;
        color: $primary;
    }
}
</style>