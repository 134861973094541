<template>
    <div class="vstack justify-content-center gap-3 p-3 px-4 flex-grow-1">
        <div class="vstack gap-3">
            <button class="btn btn-tnb-gradient w-100" @click="emits('next')">Seterusnya</button>
            <button
                class="btn btn-outline-primary rounded-pill w-100" 
                @click="removeRecorderdVideo">
                {{ selectedMediaType === 'video' ? 'Rekod Semula' : 'Ambil Semula' }}
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';
import useFabricCanvas from '../../composables/use-fabric-canvas';
import { useDesignStore, usePreviewStore } from '../../stores';
import { storeToRefs } from 'pinia';

const emits = defineEmits(['back', 'next']);

const previewStore = usePreviewStore();
const { recordStreamCamRef, selectedMediaType } = storeToRefs(previewStore);

const designStore = useDesignStore();

const removeRecorderdVideo = async () => {
    const [canvas] = useFabricCanvas();
    let removeMediaElementList = ['record-stream-video-element', 'record-stream-picture-element'];
    removeMediaElementList.forEach(item => {
        if (canvas.getObjects().find(data => data.name === item)) {
            designStore.removeFabricCanvasElement(item);
        }
    });

    (recordStreamCamRef.value as HTMLVideoElement).pause();
    (recordStreamCamRef.value as HTMLVideoElement).currentTime = 0;

    selectedMediaType.value = null;

    emits('back');
}

onMounted(() => {
    if (recordStreamCamRef.value && selectedMediaType.value === 'video') {
        (recordStreamCamRef.value as HTMLVideoElement).play();
    }
})

onUnmounted(() => {
    if (recordStreamCamRef.value && selectedMediaType.value === 'video') {
        (recordStreamCamRef.value as HTMLVideoElement).pause();
        (recordStreamCamRef.value as HTMLVideoElement).currentTime = 0;
    }
})

</script>

<style lang="scss"></style>