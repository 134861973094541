<template>
    <slot name="activator" :show="show" :hide="hide"></slot>
    <Teleport :to="teleportTarget">
        <div v-bind="$attrs"
            class="modal modal-generic fade"
            tabindex="-1"
            ref="modal"
            v-on="{
                'shown.bs.modal': onShown,
                'show.bs.modal': onShow,
                'hidden.bs.modal': onHidden,
                'hide.bs.modal': onHide,
            }">
            <div class="modal-dialog modal-dialog-centered" :class="modalDialogClass"> 
                <div class="modal-content" :class="modalContentClass">
                    <slot name="modal-header" :closeIconClass="closeIconClass" :hide="hide">
                        <div class="modal-header">
                            <h5 class="modal-title" v-if="modalTitle">{{ modalTitle }}</h5>
                            <button
                                type="button"
                                class="ms-auto btn btn-close btn-overlay-dark p-2 rounded-circle"
                                aria-label="Close"
                                data-bs-dismiss="modal">
                                <Icon symbol="x" :class="closeIconClass" class="text-white"></Icon>
                            </button>
                        </div>
                    </slot>

                    <div class="modal-body">
                        <slot name="modal-body" :show="show" :hide="hide" :isActive="isActive"></slot>
                    </div>
                    <div v-if="$slots['modal-footer']" class="modal-footer">
                        <slot name="modal-footer"></slot>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, PropType, ref } from 'vue';
import Icon from './icon.vue';
import appConstants from "@/modules/website/app.constants";
import { Modal } from 'bootstrap';

const props = defineProps({
    size: {
        type: String as PropType<'modal-m'|'modal-lg'|'modal-xl'>,
        default: "modal-m"
    },
    backdrop: {
        type: [Boolean, String] as PropType<true|'static'>,
        default: true
    },
    keyboard: {
        type: Boolean,
        default: true
    },
    focus: {
        type: Boolean,
        default: true
    },
    closeIconClass: {
        type: String,
        default: "icon-m"
    },
    modalBackground: String,
    modalTitle: String,
    isFullscreen: Boolean,
    modalDialogScrollable: Boolean,
    eager: Boolean,
})

const emit = defineEmits(['shown', 'show', 'hide', 'hidden'])

const modal = ref<HTMLElement|null>(null);
let modalInstance: Modal;

const isActive = ref(false);

const teleportTarget = appConstants.MODALS_TELEPORT_TARGET;

onMounted(async () => {
    const Modal = (await import('bootstrap/js/dist/modal')).default
    if (!modal.value) return;
    modalInstance = Modal.getOrCreateInstance(
        modal.value,
        { keyboard: props.keyboard, focus: props.focus, backdrop: props.backdrop }
    );
    if (props.eager) {
        modalInstance.show();
    }
})

onBeforeUnmount(() => {
    if (!modal.value) return;
    modalInstance.dispose()
})

const show = () => modalInstance.show()

const hide = () => modalInstance.hide()

const onShow = () => {
    emit('show');
    isActive.value = true;
}
const onShown = () => {
    emit('shown');
    modal.value?.querySelector<HTMLInputElement>('[autofocus]')?.focus();
}
const onHide = () => emit('hide');
const onHidden = () => {
    emit('hidden');
    isActive.value = false;
}

const modalDialogClass = computed(() => {
    let classList = [];
    if (props.size) {
        classList.push(props.size);
    }
    if (props.modalDialogScrollable) {
        classList.push('modal-dialog-scrollable');
    }
    if (props.isFullscreen) {
        classList.push('modal-fullscreen');
    }
    return classList.join(' ');
})

const modalContentClass = computed(() => {
    let classList: string[] = [];
    if (props.modalBackground) {
        classList.push('bg-' + props.modalBackground)
    }
    return classList;
})

defineExpose({ show, hide })
</script>

<style lang="scss">
.modal-generic {
    .modal-dialog {

        .modal-content {
            background: radial-gradient(126.08% 58.3% at 52.64% 32.5%, #04814C 0%, #0C3247 100%);
            border-radius: 16px;
        }

        &-scrollable {
            .modal-body {
                overscroll-behavior: contain;
            }
        }
    }

    &.fade {
        .modal-dialog {
            transform: scale(0.9);
            transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
        }
    }

    &.show {
        .modal-dialog {
            transform: none;
        }
    }
}
</style>