<template>
    <div class="vstack justify-content-center gap-3 p-3 px-4 flex-grow-1">
        <div class="vstack justify-content-center gap-3 d-none">
            <div class="debug">
                <video ref="streamCamRef" class="w-auto h-auto" playsInline muted></video>
                <img ref="picTakenPlaceholderRef">
                <div class="overflow-visible">
                </div>
                <canvas ref="canvasPlaceholderRef"></canvas>
                <button class="btn btn-primary" @click="checkItemIncanvas"> check item in canvas</button>
            </div>
        </div>

        <div class="vstack gap-4">
            <FadeInOut mode="out-in" :duration="200">
                <div v-if="enableStartButton"
                    class="hstack justify-content-center align-items-center mt-3">
                    <div class="left w-25"></div>
                    <RecordAnimaitedButton
                        @click="clickStartButton"
                        class="w-50"
                    ></RecordAnimaitedButton>
                    <div class="right w-25">
                        <div class="gallery-button">
                            <button @click="() => openFileDialog()" class="btn btn-overlay-light p-2 rounded" type="button">
                                <Icon symbol="image-fill" size="l"></Icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div v-else class="vstack gap-3 align-items-center">
                    <Spinner width="64" height="64"></Spinner>
                    <p class="heading-s text-muted mb-0">Memuatkan kamera</p>
                </div>
            </FadeInOut>
        </div>

    </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import { fabric } from "fabric";
import useFabricCanvas from '../../composables/use-fabric-canvas';
import { useDesignStore } from '../../stores';
import RecordAnimaitedButton from '../record-animaited-button.vue';
import appConstants from '../../app.constants';
import { Icon, Spinner } from '@/modules/core/components';
import { FadeInOut } from "vue3-transitions";
import { useFileDialog } from '@vueuse/core';

const { open: openFileDialog, onChange } = useFileDialog({
    accept: 'image/png, image/jpeg',
    multiple: false,
});

const emits = defineEmits(['completed']);

const streamCamRef = ref();
const canvasPlaceholderRef = ref();
const picTakenPlaceholderRef = ref<HTMLImageElement>();
const enableStartButton = ref(false);
const designStore = useDesignStore();
let mediaStreamInstance: MediaStream | null;

onChange(async files => {
    console.log('change', files)
    const imageFile = files?.item(0);
    if (!imageFile) return;

    await removeStreamVideoFromFabric(true);
    const url = URL.createObjectURL(imageFile);
    picTakenPlaceholderRef.value!.setAttribute("src", url);
    placePicIntoFabric()
    emits('completed')
})

const clickStartButton = async () => {
    await removeStreamVideoFromFabric();
    takeaPicture();
    await placePicIntoFabric();

    emits('completed');
}

const removeStreamVideoFromFabric = async (loadWebcamStream = true) => {
    const [canvas] = useFabricCanvas();

    if (canvas.getObjects().find(item => item.name === 'record-stream-picture-element')) {
        designStore.removeFabricCanvasElement('record-stream-picture-element');
        if (loadWebcamStream) {
            await loadStreamingWebcam();
        }
    }

}

const takeaPicture = () => {
    const context = canvasPlaceholderRef.value.getContext("2d");
    if (streamCamRef.value.videoWidth && streamCamRef.value.videoHeight) {
        canvasPlaceholderRef.value.width = streamCamRef.value.videoWidth;
        canvasPlaceholderRef.value.height = streamCamRef.value.videoHeight;
        context.drawImage(streamCamRef.value, 0, 0, canvasPlaceholderRef.value.width, canvasPlaceholderRef.value.height);

        const data = canvasPlaceholderRef.value.toDataURL("image/png");
        picTakenPlaceholderRef.value!.setAttribute("src", data);
    }
}


const placePicIntoFabric = async () => {

    const [canvas] = useFabricCanvas();
    const imgEl = picTakenPlaceholderRef.value!.cloneNode(true) as HTMLImageElement;

    return new Promise<void>((resolve, reject) => {
        imgEl.onload = async () => {
            const imgInstance = new fabric.Image(imgEl, {
                name: 'record-stream-picture-element',
                originX: 'center',
                originY: 'center',
                width: streamCamRef.value?.videoWidth,
                height: streamCamRef.value?.videoHeight,
                flipX: streamCamRef.value ? true : false,
                selectable: false,
            });

            if (!streamCamRef.value) {
                imgInstance.scaleToWidth(canvas.getWidth() * 0.8)
                imgInstance.centerH()
            } else {
                imgInstance.scale(.85)
            };

            canvas.add(imgInstance);
            canvas.centerObject(imgInstance);
            if (!streamCamRef.value) {
                imgInstance.top = canvas.getHeight() * 0.4;
            }
            imgInstance.moveTo(0);
            canvas.renderAll();
            resolve();
        }
    });

}

const streamCamElement = () => {
    const { videoWidth, videoHeight } = streamCamRef.value;

    const webcam = new fabric.CustomVideo(streamCamRef.value, {
        name: 'stream-video-element',
        originX: 'center',
        originY: 'center',
        width: videoWidth,
        height: videoHeight,
        flipX: true,
        selectable: false,
    });

    webcam.scale(.85);
    return webcam;
}

const streamCamElementBK = () => {
    let webcam = new fabric.CustomVideo(streamCamRef.value, {
        name: 'stream-video-element',
        originX: 'center',
        originY: 'center',
        width: streamCamRef.value.videoWidth,
        height: streamCamRef.value.videoHeight,
        flipX: true,
        selectable: false,
    });

    webcam.scale(.85);
    return webcam;
}

const loadStreamingWebcamBK = async () => {
    const constraints = {
        audio: true,
        video: {
            "width": appConstants.CAMERA_CONSTRAINT.WIDTH,
            "height": appConstants.CAMERA_CONSTRAINT.HEIGHT,
            "frameRate": 30
        },
    };

    navigator.mediaDevices
        .getUserMedia(constraints)
        .then((mediaStream) => {
            mediaStreamInstance = mediaStream;
            streamCamRef.value.srcObject = mediaStream;
            streamCamRef.value.onloadedmetadata = () => {

                streamCamRef.value.play();

                let webcam = streamCamElement();

                const [canvas] = useFabricCanvas();
                canvas.add(webcam).centerObject(webcam);
                webcam.moveTo(0);
                (webcam.getElement() as HTMLVideoElement).play();

                enableStartButton.value = true;
            };
        })
        .catch((err) => {
            console.error(`${err.name}: ${err.message}`);
        });
}

const loadStreamingWebcam = async () => {
    const constraints = {
        audio: true,
        video: {
            "width": appConstants.CAMERA_CONSTRAINT.WIDTH,
            "height": appConstants.CAMERA_CONSTRAINT.HEIGHT,
            "frameRate": 30
        },
    };

    navigator.mediaDevices
        .getUserMedia(constraints)
        .then((mediaStream) => {
            mediaStreamInstance = mediaStream;
            streamCamRef.value.srcObject = mediaStream;
            streamCamRef.value.onloadedmetadata = () => {
                streamCamRef.value.play();
                streamCamRef.value.onloadedmetadata = null; // Remove the event listener

                // Delay the call to streamCamElement until the dimensions have been loaded
                setTimeout(() => {
                    const webcam = streamCamElement();
                    const [canvas] = useFabricCanvas();
                    canvas.add(webcam).centerObject(webcam);
                    webcam.moveTo(0);
                    (webcam.getElement() as HTMLVideoElement).play();
                    enableStartButton.value = true;
                }, 500); // Change the delay time as needed
            };
        })
        .catch((err) => {
            console.error(`${err.name}: ${err.message}`);
        });
}

const stopStream = () => {
    if (mediaStreamInstance) {
        mediaStreamInstance.getTracks().forEach(track => {
            track.stop();
        });
    }
};

const checkItemIncanvas = () => {
    const [canvas] = useFabricCanvas();
    console.log('canvas._objects', canvas._objects);
}


onMounted(async () => {
    await loadStreamingWebcam();
})

onUnmounted(() => {
    // stopStream();
    designStore.removeFabricCanvasElement('stream-video-element');
})

</script>

<style lang="scss">
.btn.btn-radio-record-duration {
    border-color: $white;
    transition: border-color 0.2s ease-in-out 0s;

    &.active {
        border-color: $primary;
        color: $primary;
    }
}

.gallery-button {

    input[type=file] {
        display: none;
    }
}
</style>