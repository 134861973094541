import { fabric } from 'fabric';

fabric.CustomVideo = fabric.util.createClass(fabric.Image, {
  type: 'customvideo',
  cropRect: null,

  initialize: function (video: any, options: any) {
    const defaultOpts = {
      lockRotation: true,
      objectCaching: true,
      cacheProperties: ['time']
    }
    options = options || {}

    this.callSuper('initialize', video,
      Object.assign({}, defaultOpts, options))
  },
  _draw: function (video: any, ctx: any, w: any, h: any) {
    const c = this.cropRect
    const d = {
      x: -this.width / 2,
      y: -this.height / 2,
      w: this.width,
      h: this.height
    }
    if (c) {
      ctx.drawImage(video, c.x, c.y, c.w, c.h, d.x, d.y, d.w, d.h)
    } else {

      // d.x = -415/2  = -207.5
      // d.y = -518.75/2 = -259.375
      // d.w = 415
      // d.h = 518.75 
      ctx.drawImage(video, d.x, d.y, d.w, d.h)
    }
  },
  // _draw: function (video: any, ctx: any, w: any, h: any) {
  //   const c = this.cropRect
  //   const d = {
  //     x: -this.width / 2,
  //     y: -this.height / 2,
  //     w: this.width,
  //     h: this.height
  //   }
  //   if (c) {
  //     ctx.drawImage(video, c.x, c.y, c.w, c.h, d.x, d.y, d.w, d.h)
  //   } else {
  //     ctx.drawImage(video, d.x, d.y, d.w, d.h)
  //   }
  //   let factor = this.width / img.naturalWidth * img.naturalHeight > window.innerHeight ? can.height / img.naturalHeight : can.width / img.naturalWidth;
  // },

  // let factor = can.width / img.naturalWidth * img.naturalHeight > window.innerHeight ? can.height / img.naturalHeight : can.width / img.naturalWidth;
  // ctx.drawImage(img, 0, 0, img.naturalWidth * factor, img.naturalHeight * factor);

  _render: function (ctx: any) {
    // console.log('rendered', this.cropLeft)
    this._draw(this.getElement(), ctx)
  }
})

