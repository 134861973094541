import { defineStore, storeToRefs } from "pinia";
import useFabricCanvas from "../composables/use-fabric-canvas";
import { fabric } from "fabric";
import appConstants from "../app.constants";

export interface IPostCardSettings {
    width: number,
    height: number,
}

export interface IDesignState {
    wrapperRef: null | HTMLDivElement;
    canvasRef: null | HTMLCanvasElement;
    canvasId: null | string;
    history: Array<fabric.Object>;
    selectedTheme: number;
    postCardDimensionSettings: IPostCardSettings,
    isBusy: boolean,
    countDown: number,
    isCountingDown: boolean,
}

export const useDesignStore = defineStore('design', {
    state: (): IDesignState => ({
        wrapperRef: null,
        canvasRef: null,
        canvasId: appConstants.CANVAS_FABRIC_ID,
        history: [],
        selectedTheme: 1,
        postCardDimensionSettings: {
            width: 0,
            height: 0,
        },
        isBusy: false,
        countDown: 3,
        isCountingDown: false,
    }),
    getters: {
        postCardDimensionSettings() {
            // height / width * 100% 
            // 535    / 428   * 100%   = 125%
            // ?      / 316   * 100%   = 125%
            // 125    / 100   * 316%   = 395px
            const html = document.documentElement;
            const width = window.innerWidth || html.clientWidth;
            const height = 125 / 100 * width;

            if (window.screen.width > 428) {
                console.log(`setHeightWidthFromRatio(): set canvas width : ${appConstants.CARD_SIZE.WIDTH} / height : ${appConstants.CARD_SIZE.HEIGHT}.`);
                const { HEIGHT, WIDTH } = appConstants.CARD_SIZE;
                return { height: HEIGHT, width: WIDTH };
            }

            console.log(`setHeightWidthFromRatio(): Set Canvas width : ${width} / height : ${height}.`);
            return { height, width };
        }

    },
    actions: {
        undo() {
            const [canvas] = useFabricCanvas();

            if (canvas._objects.length > 0) {
                const canvasObj = canvas._objects.pop();
                if (!canvasObj) return;
                this.history.push(canvasObj);
                canvas?.renderAll();
            }
        },
        redo() {
            const [canvas] = useFabricCanvas();

            if (this.history.length > 0) {
                const canvasObj = this.history.pop()
                if (!canvasObj) return;
                canvas.add(canvasObj);
            }
        },
        removeFabricCanvasElement(elementName: string) {
            const [canvas] = useFabricCanvas();

            canvas._objects.find(item => {
                if (!item) return;

                if (item.name === elementName) {
                    canvas.remove(item);
                    canvas.discardActiveObject();
                    canvas.requestRenderAll();

                }
            })
        },
        countDownTimer(callback: () => void) {
            this.isCountingDown = true;
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    console.log('countDownTimer(): countdown to start recording', new Date().getSeconds())
                    this.countDownTimer(callback);
                }, 1000)
            } else {
                this.isCountingDown = false;
                callback && callback();
            }
        },
        seeCanvas() {
            const [canvas] = useFabricCanvas();
            console.log('canvas._objects', canvas._objects);
            const objectXY = canvas._objects.map((item) => {

                return {
                    id: item.name,
                    top: Number(((item.top ?? 0) * 100 / this.postCardDimensionSettings.height).toFixed(2)),
                    left: Number(((item.left ?? 0) * 100 / this.postCardDimensionSettings.width).toFixed(2)),
                    scale: Number((item.scaleX)?.toFixed(2)),
                    flipX: false,
                }
            })

            console.log('objectXY', objectXY);
            console.log('objectXY in Json', JSON.stringify(objectXY));
        }
    }
})
