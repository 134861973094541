<template>
    <div class="previewing-screen">
        <img src="/images/design-layout.webp" class="position-absolute top-0 left-0 w-100 h-100"
            alt="design-layout-background-img">
        <div class="screen-content">
            <div class="controls-upper">
                <div class="hstack justify-content-between gap-4 px-4 flex-grow-1">
                    <p class="heading-s mb-0">Kad e-Ucapan TNB Anda Sudah Sedia!</p>
                </div>
            </div>
            <div v-if="videoBlob" class="video-content">
                <video ref="previewVideoRef" id="preview-video" autoplay controls loop playsinline></video>
            </div>
            <div v-if="imageString" class="img-content">
                <img ref="previewImageRef" id="preview-img" class="img-fluid" alt="tnb-card">
            </div>
            <div class="controls-bottom">
                <FadeInOut mode="out-in" :duration="200">
                    <div v-if="isTranscoding" class="vstack justify-content-center gap-4 px-4 flex-grow-1">
                        <p class="heading-s text-center mb-0">Sila tunggu sekitar 60 saat sementara kad anda dihasilkan.</p>
                        <div class="vstack align-items-center">
                            <div>
                                <Spinner width="64" height="64"></Spinner>
                            </div>
                        </div>
                    </div>
                    <div v-else class="vstack justify-content-center gap-3 p-3 px-4 flex-grow-1">
                        <button v-if="isSupported" class="btn btn-tnb-gradient w-100"
                            @click="shareToSocialMedia">Kongsi
                            Sekarang</button>
                        <button
                            class="btn btn-outline-primary rounded-pill w-100"
                            :class="{ 'btn-tnb-gradient rounded-0': !isSupported }"
                            @click="backToHomePage">
                            Cipta Yang Lain
                        </button>

                        <a :href="downloadHref" class="btn btn-none p-0 gap-3" :download="fileName">
                            <Icon symbol="download" size="m"></Icon>
                            <span>Muat Turun Sekarang</span>
                        </a>
                        
                    </div>
                </FadeInOut>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { usePreviewStore } from '../../stores';
import { useShare } from '@vueuse/core'
import { FadeInOut } from "vue3-transitions";
import { urltoFile } from '@/modules/core/utilities';
import { Icon, Spinner } from '@/modules/core/components';
import { storeToRefs } from 'pinia';

const emits = defineEmits(['back']);

const fileName = ref();
const { share, isSupported } = useShare();
const previewVideoRef = ref<HTMLVideoElement>();
const previewImageRef = ref<HTMLImageElement>();
const downloadHref = ref('');

const previewStore = usePreviewStore();
const { videoBlob, imageString, selectedMediaType } = storeToRefs(previewStore);
const sharingFile = ref<File | null>();
const isTranscoding = ref(true);

const setBlobIntoVideo = () => {
    if (!previewVideoRef.value) return;
    if (!videoBlob.value) return;

    let blob = new Blob([videoBlob.value], { type: 'video/mp4' });
    const createObjectURL = window.URL.createObjectURL || window.webkitURL.createObjectURL;

    previewVideoRef.value.src = createObjectURL(blob);
    downloadHref.value = previewVideoRef.value.src;

    previewVideoRef.value.onloadedmetadata = () => {
        if (!previewVideoRef.value) return;
        previewVideoRef.value.play();
    };

    previewVideoRef.value.addEventListener('play', onVideoPlay);

    sharingFile.value = new File([videoBlob.value], fileName.value, { type: 'video/mp4' })
}

const setImageIntoImg = () => {
    if (!previewImageRef.value) return;
    if (!imageString.value) return;

    previewImageRef.value.src = imageString.value;
    downloadHref.value = previewImageRef.value.src;

    urltoFile(imageString.value, fileName.value, 'image/png')
        .then(function (file) {
            sharingFile.value = file
        });

}

const onVideoPlay = () => {
  console.log('onVideoPlay(): video generated and play succesfully.');
}

const backToHomePage = () => {
    videoBlob.value = null;
    imageString.value = null;
    selectedMediaType.value = null;
    emits('back');
}

const shareToSocialMedia = async () => {
    if (!sharingFile.value) return;

    let sharingItem = {
        files: [sharingFile.value],
    }

    share(sharingItem);
}

onMounted(async () => {
    if (videoBlob.value) {
        fileName.value = 'Selamat-Hari-Raya.mp4';
        setBlobIntoVideo();
        
    } else {
        fileName.value = 'Selamat-Hari-Raya.png';
        setImageIntoImg();
    }

    isTranscoding.value = false;
})

</script>

<style lang="scss" scoped>
.previewing-screen {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .screen-content {
        z-index: 1;
    }

    .controls-upper {
        display: flex;
        height: 50px;
    }

    .controls-bottom {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    @include media-breakpoint-up(md) {
        height: 750px;
    }

}

video {
    width: 100%;
    height: auto;
}
</style>