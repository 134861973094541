<template>
    <svg class="icon" :class="iconClass" aria-hidden="true">
        <use :href="'#icon-' + symbol"></use>
    </svg>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";
import { IconSize } from "@/modules/core/types";

const props = defineProps({
    symbol: String,
    size: String as PropType<IconSize>
})

const iconClass = computed(() => {
    const classList: string[] = [];
    if (props.size) {
        classList.push('icon-' + props.size);
    }
    return classList.join(' ');
})
</script>
