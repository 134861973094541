import { fabric } from 'fabric'

fabric.Canvas.prototype.selectionColor = 'rgba(0, 191, 255, .3)'
fabric.Canvas.prototype.selectionBorderColor = 'rgba(0, 191, 255, .5)'
fabric.Canvas.prototype.selectionLineWidth = 2
fabric.Object.prototype.borderColor = '#FFFFFF'
fabric.Object.prototype.transparentCorners = false
fabric.Object.prototype.cornerColor = '#FFFFFF'
fabric.Object.prototype.cornerStyle = 'circle'
fabric.Object.prototype.cornerSize = 10
