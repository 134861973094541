<template>
  <canvas id="fabricCanvas" ref="canvasRef"></canvas>
</template>

<script setup lang="ts">
import { onMounted, toRefs } from "vue";
import useFabricCanvas from "../composables/use-fabric-canvas";
import { useDesignStore } from "../stores";

const design = useDesignStore();

const [canvas, initCanvas] = useFabricCanvas();
const { canvasRef } = toRefs(design);


onMounted(() => {
  initCanvas({
    height: design.postCardDimensionSettings.height,
    width: design.postCardDimensionSettings.width,
  });
})

</script>