<template>
    <div class="vstack justify-content-start gap-3 p-3 px-4 flex-grow-1">
        <div class="option-list hstack justify-content-around gap-3">
            <img ref="defaultOptionRef" src="/images/text/greeting-three.svg" class="btn btn-none btn-option-item"
                :class="{ 'selected': selectedOption === 3 }" @click="(e) => selectOption(3, e)" alt="">
            <img src="/images/text/greeting-two.svg" class="btn btn-none btn-option-item"
                :class="{ 'selected': selectedOption === 2 }" @click="(e) => selectOption(2, e)" alt="">
            <img src="/images/text/greeting-one.svg" class="btn btn-none btn-option-item"
                :class="{ 'selected': selectedOption === 1 }" @click="(e) => selectOption(1, e)" alt="">
        </div>
        <button class="btn btn-tnb-gradient w-100" @click="continueHandler">Selesai dan Previu</button>
        <!-- <button @click="designStore.seeCanvas"> canvas</button> -->
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { fabric } from "fabric";
import useFabricCanvas from '../../composables/use-fabric-canvas';
import { useDesignStore } from '../../stores';
import { storeToRefs } from 'pinia';

const emits = defineEmits(['next']);

const selectedOption = ref(1);
const defaultOptionRef = ref<HTMLElement>();
const [canvas] = useFabricCanvas();
const designStore = useDesignStore();
const { selectedTheme } = storeToRefs(designStore);

const defaultTextboxValue = 'Taip nama anda';
let coords = {
    x: 0,
    y: 0
};

const addTextbox = () => {

    let nameTextBox = new fabric.IText(defaultTextboxValue, {
        name: 'textbox-element',
        width: 100,
        fontSize: 16,
        fontFamily: 'Roboto',
        fontWeight: 500,
        fill: '#EDDC98',
        top: designStore.postCardDimensionSettings.height - 50,
        left: (designStore.postCardDimensionSettings.width / 2),
        textAlign: 'center',
        originX: 'center',
    });

    nameTextBox.on('mousedown', function (obj) {
        coords = canvas.getPointer(obj.e);
    });

    nameTextBox.on('mouseup', function (obj) {
        let e = canvas.getPointer(obj.e) as fabric.Point;
        if (e.x == coords.x && e.y == coords.y) {
            const objTarget = obj.target as fabric.IText;
            objTarget.enterEditing();
            // @ts-ignore
            objTarget.setCursorByClick(e);
        }
    });

    nameTextBox.on("editing:entered", function (e) {
        var obj = canvas.getActiveObject() as fabric.IText;
        if (obj.text === defaultTextboxValue) {
            obj.selectAll();
            obj.text = "";
            obj.hiddenTextarea!.value = "";
            obj.dirty = true;
            obj.setCoords();
            canvas.renderAll();
        }
    });

    nameTextBox.on("editing:exited", function (e) {
        var obj = canvas.getActiveObject() as fabric.IText;
        if (obj.text === '') {
            obj.text = defaultTextboxValue;
            obj.dirty = true;
            obj.setCoords();
            canvas.renderAll();
        }
    });

    canvas.add(nameTextBox);
}

const selectOption = (item: number, e: Event) => {
    selectedOption.value = item;

    const target = e.target as HTMLImageElement;
    const url = target.src;
    designStore.removeFabricCanvasElement('item-text-element');
    fabric.loadSVGFromURL(url, (objects, options) => {
        let item = fabric.util.groupSVGElements(objects, {
            ...options,
            name: 'item-text-element',
            top: (selectedTheme.value === 2 ? 78 : 74) * designStore.postCardDimensionSettings.height / 100,
            // selectable: false,
        });

        item.scale(.4);
        canvas.add(item).centerObjectH(item).renderAll();
    });

}

const continueHandler = () => {
    canvas._objects.find(item => {
        if (!item) return;
        if (item.name === 'textbox-element' && item instanceof fabric.IText && item.text === defaultTextboxValue) {
            canvas.remove(item);
        }
    })
    canvas.discardActiveObject().renderAll();
    emits('next');
}

onMounted(() => {
    defaultOptionRef.value?.click();

    addTextbox();
})

</script>

<style lang="scss" scoped>
.btn.btn-option-item {
    border-radius: .5rem;
    border-width: 2px;
    overflow: hidden;
    padding: .5rem;
    border: solid 2px rgb($white, .3);
    background: radial-gradient(126.08% 58.3% at 52.64% 32.5%, #04814C 0%, #0C3247 100%);
    width: 70px;
    height: 70px;
    transition: border-color 0.2s ease-in-out 0s;

    &.selected {

        border: solid 2px $primary;
    }
}
</style>