import { fabric } from 'fabric';
import type { Canvas } from 'fabric/fabric-impl';
import { useDesignStore } from '../stores/design';
import './initFabricPrototype.ts';
import './fabricSprite.ts';
import './fabricCustomVideo.ts';

let canvas: null | Canvas = null

const initCanvas = (settings: { width: number, height: number }) => {
  const design = useDesignStore()
  canvas = new fabric.Canvas(design.canvasRef, {
    width: settings.width,
    height: settings.height,
    // preserveObjectStacking: true,
    selection: false,
    allowTouchScrolling: true,
    controlsAboveOverlay:true,
  })

  canvas.setBackgroundColor('#FFFFFF', () => { console.log('initCanvas(): completed initial canvas.') });
  canvas.renderAll();
}

type TUseCanvas = [Canvas, typeof initCanvas]
export default (): TUseCanvas => [canvas as Canvas, initCanvas]
