/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let currentOS = 'unknown';

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        currentOS = 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
        currentOS = 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        currentOS = 'iOS';
    }

    console.log('currentOS', currentOS);
    return currentOS;
}

export const checkIosVersion = () => {
    var agent = window.navigator.userAgent,
        start = agent.indexOf('OS ');

    console.log('agent : ', agent);
    if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
        return window.Number(agent.substr(start + 3, 4).replace('_', '.'));
    }
    return 0;
}