<template>
    <div class="vstack justify-content-start gap-3 p-3 px-4 flex-grow-1">

        <div class="vstack justify-content-center gap-3 d-none">
            <div class="debug">
                <p>postCardDimensionSettings</p>
                <p>Width: {{ postCardDimensionSettings?.width }}</p>
                <p>height: {{ postCardDimensionSettings?.height }}</p>
                <hr />
                <video ref="streamCamRef" :width="postCardDimensionSettings.width"
                    :height="postCardDimensionSettings.height" playsInline muted></video>
                <!-- <a ref="downloadPreviewShareRef" class="btn btn-primary" role="button">Download</a> -->
                <!-- <button class="btn btn-primary" @click="checkItemIncanvas"> check item in canvas</button> -->
            </div>
        </div>

        <span class="body-l text-center mb-2">Rekod (7 saat)</span>
        <FadeInOut mode="out-in" :duration="200">
            <div v-if="enableRecordButton" class="hstack justify-content-center align-items-center mt-3"
                @click="clickStarRecordButton">
                <RecordAnimaitedButton ref="recordAnimatedButtonRef" :timer="selectedRecordPeriod">
                </RecordAnimaitedButton>
            </div>
            <div v-else class="vstack gap-3 align-items-center">
                <Spinner width="64" height="64"></Spinner>
                <p class="heading-s text-muted mb-0">Memuatkan kamera</p>
            </div>
        </FadeInOut>

    </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { fabric } from "fabric";
import { delay } from '@/modules/core/utilities';
import useFabricCanvas from '../../composables/use-fabric-canvas';
import { useDesignStore, usePreviewStore } from '../../stores';
import RecordAnimaitedButton from '../record-animaited-button.vue';
import { FadeInOut } from "vue3-transitions";
import { useMediaRecorder } from '../../composables';
import appConstants from '../../app.constants';
import { Spinner } from '@/modules/core/components';

const emits = defineEmits(['completed']);

const streamCamRef = ref();
const previewStore = usePreviewStore();
const { recordStreamCamRef, selectedRecordPeriod, isRecordingWebCam } = storeToRefs(previewStore);

const downloadPreviewShareRef = ref();

const recordAnimatedButtonRef = ref();

const designStore = useDesignStore();
const { countDown, postCardDimensionSettings } = storeToRefs(designStore);

const checkItemIncanvas = () => {
    const [canvas] = useFabricCanvas();
    console.log('canvas._objects', canvas._objects);
}

const enableRecordButton = ref(false);
const isVideoRecorded = ref(false);
let mediaStreamInstance: MediaStream | null;

const clickStarRecordButton = async () => {
    if (isRecordingWebCam.value) return;

    designStore.countDownTimer(() => {
        countDown.value = 3;
        console.log('clickStarRecordButton(): start recording.');
        recordAnimatedButtonRef.value.videoRecordingCountdown();
        recordVideoHandler();
    });
}

const recordVideoHandler = async () => {
    if (isRecordingWebCam.value) return;
    if (!streamCamRef.value.srcObject) return;

    const [canvas] = useFabricCanvas();

    if (canvas.getObjects().find(item => item.name === 'record-stream-video-element')) {
        designStore.removeFabricCanvasElement('record-stream-video-element');
        await loadStreamingWebcam();
    }

    isRecordingWebCam.value = true;
    startRecoder();
    await delay(8000);
    isRecordingWebCam.value = !isRecordingWebCam.value;
    isVideoRecorded.value = true;
    console.log('recordVideoHandler(): completed recording.');

    designStore.removeFabricCanvasElement('stream-video-element');
    let webcam = recordstreamCamElement();
    if (!webcam) return;

    canvas.add(webcam).centerObject(webcam);
    webcam.moveTo(0);

    setTimeout(() => {
        emits('completed');
    });

}

const startRecoder = async () => {
    if (!recordStreamCamRef.value) return;

    const mediaRecorder = useMediaRecorder();
    mediaRecorder.loadMediaRecorder(streamCamRef.value.srcObject);
    mediaRecorder?.start();
    await delay(7000);
    const blob = await mediaRecorder.stop();
    const createObjectURL = window.URL.createObjectURL || window.webkitURL.createObjectURL;
    recordStreamCamRef.value.src = createObjectURL(blob);
}

const streamCamElement = () => {
    let webcam = new fabric.Image(streamCamRef.value, {
        name: 'stream-video-element',
        originX: 'center',
        originY: 'center',
        flipX: true,
        width: streamCamRef.value.width,
        height: streamCamRef.value.height,
        objectCaching: false,
        selectable: false,
    });

    webcam.scale(.8);
    return webcam;
}

const recordstreamCamElement = () => {
    if (!recordStreamCamRef.value) return;

    let webcam = new fabric.Image(recordStreamCamRef.value, {
        name: 'record-stream-video-element',
        originX: 'center',
        originY: 'center',
        flipX: true,
        width: streamCamRef.value.width,
        height: streamCamRef.value.height,
        selectable: false,
    });

    webcam.scale(.8);

    return webcam;
}

const loadStreamingWebcam = async () => {
    const constraints = {
        audio: true,
        video: {
            "width": appConstants.CAMERA_CONSTRAINT.WIDTH,
            "height": appConstants.CAMERA_CONSTRAINT.HEIGHT,
            "frameRate": 30,
        },
    };

    navigator.mediaDevices
        .getUserMedia(constraints)
        .then((mediaStream) => {
            mediaStreamInstance = mediaStream;
            streamCamRef.value.srcObject = mediaStream;
            streamCamRef.value.onloadedmetadata = () => {
                streamCamRef.value.play();

                let webcam = streamCamElement();

                const [canvas] = useFabricCanvas();
                canvas.add(webcam).centerObject(webcam);
                webcam.moveTo(0);
                (webcam.getElement() as HTMLVideoElement).play();

                enableRecordButton.value = true;
            };
        })
        .catch((err) => {
            console.error(`${err.name}: ${err.message}`);
        });
}

const stopStream = () => {
    if (mediaStreamInstance) {
        mediaStreamInstance.getTracks().forEach(track => {
            track.stop();
        });
    }
};

onMounted(async () => {
    await loadStreamingWebcam();
})

onUnmounted(() => {
    // stopStream();
    designStore.removeFabricCanvasElement('stream-video-element');

})

</script>

<style lang="scss">
.btn.btn-radio-record-duration {
    border-color: $white;
    transition: border-color 0.2s ease-in-out 0s;

    &.active {
        border-color: $primary;
        color: $primary;
    }
}
</style>