
export function useMediaRecorder() {

    let mediaRecorderInstance: MediaRecorder | null = null;
    let options: MediaRecorderOptions = {
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 2500000,
    };

    const getMediaRecorderInstance = () => {
        return mediaRecorderInstance;
    }

    const loadMediaRecorder = (stream: MediaStream) => {
        console.log(`mobile device support${MediaRecorder.isTypeSupported('video/webm') ? 'video/webm' : 'video/mp4'}`);
        const mimeType = MediaRecorder.isTypeSupported('video/webm') ? 'video/webm' : 'video/mp4';
        options.mimeType = mimeType;

        mediaRecorderInstance = new MediaRecorder(stream, options);
    }

    const start = () => {
        console.log('mediaRecorderInstance', mediaRecorderInstance?.state);
        if (!mediaRecorderInstance) return;
        mediaRecorderInstance.start();
        console.log('mediaRecorder', mediaRecorderInstance?.state);
        console.log('mediaRecorder(): start recording');
    }

    const stop = () => {
        return new Promise<Blob>((resolve, reject) => {
            if (mediaRecorderInstance) {
                mediaRecorderInstance.addEventListener('dataavailable', ({ data }) => {
                    console.log(`dataavailable return : file type: ${data.type} | file size: ${data.size}`);
                    return resolve(data)
                });

                console.log('mediaRecorder state:' + mediaRecorderInstance.state);
                if (mediaRecorderInstance.state === 'recording') {
                    mediaRecorderInstance.stop();
                    mediaRecorderInstance = null;
                    console.log('mediaRecorder(): stop recording');
                    return;
                }

                console.log('mediaRecorder(): stop recording not found / stop');

            } else {
                reject()
            }
        });
    }

    const isTypeSupported = (format: string) => {
        return MediaRecorder.isTypeSupported(format);
    }

    return {
        getMediaRecorderInstance,
        loadMediaRecorder,
        isTypeSupported,
        start,
        stop,
    }
}
