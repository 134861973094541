<template>
    <div class="landing-screen p-4 py-5">
        <div class="d-flex align-items-center justify-content-center mb-4">
            <img src="/images/svg/tnb-logo.svg" class="img-fluid" alt="tnb-logo">
        </div>

        <div class="flex-grow-1 pt-4">
            <h3 class="heading-l text-center mb-3">Kad e-Ucapan TNB</h3>
            <div class="hstack justify-content-center mb-4">
                <img src="/images/svg/header.svg" class="tnb-text-logo img-fluid" alt="TNB Selamat Hari Raya">
            </div>
            <div class="d-flex flex-column mb-4 px-3">
                <button class="btn btn-tnb-gradient mb-3" @click="startDesignPostCard">Cipta Sekarang</button>
            </div>
    
            <div class="text-center">
                <a href="https://www.tnb.com.my/terms-policy/personal-data-protection-policy-pdpa" class="text-white">Polisi Privasi</a>
            </div>
        </div>

        <div></div>

        <p class="company-reg">Tenaga Nasional Berhad 199001009294&nbsp;&nbsp;&nbsp;(200866-W)</p>
    </div>
</template>

<script lang="ts" setup>
import { useShare } from '@vueuse/core'
import { ref } from 'vue';
import ShareModal from '../share-modal.vue';

const emits = defineEmits(['continue'])
const { share, isSupported } = useShare();
const ShareModalRef = ref<InstanceType<typeof ShareModal>>();

const startDesignPostCard = () => {
    emits('continue');
}

const shareToSocialMedia = async () => {

    if (isSupported) {
        let shareItem = {
            title: 'Selamat Hari Raya Aidilfitri',
            text: `Click on ${window.location.href} to create a Hari Raya Aidilfitri e-Greeting for you to send to your relatives and friends.`,
            url: window.location.href,
        }

        return share(shareItem);
    }

    ShareModalRef.value?.show();
}

</script>

<style lang="scss">
.landing-screen {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-image: url('/images/landing-layout.webp');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .company-reg {
        font-size: 10px;
        writing-mode: vertical-rl;
        transform: translateY(-50%) rotate(180deg);
        position: absolute;
        top: 50%;
        right: 8px;
        padding: 0;
        margin: 0;
        pointer-events: none;
    }

    @include media-breakpoint-up(md) {
        height: 750px;
        border-radius: 0.5rem;
        border-width: 3px;
    }

}

.tnb-text-logo {
    height: 150px;
}

.separate-text {
    p {
        display: flex;
        flex-direction: row;
        font-weight: $font-weight-semibold;
    }

    p:before,
    p:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid rgb(#FFFFFF, .30);
        margin: auto;
    }

    p:before {
        margin-right: 2rem;
    }

    p:after {
        margin-left: 2rem;
    }
}
</style>
