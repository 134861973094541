import debounce from 'lodash/debounce';

export function setCssHelperVars() {
    const run = () => {
        const contentMinFullHeight = (window.innerHeight ?? 0);
        document.documentElement.style.setProperty('--content-min-full-height', contentMinFullHeight + 'px');
        setScrollbarWidthCssVar()
    }

    window.addEventListener('resize', debounce(run, 300));
    run();
}

export function setScrollbarWidthCssVar() {
    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    if (outer.parentNode) {
        outer.parentNode.removeChild(outer);
    }

    document.documentElement.style.setProperty('--scrollbar-width', scrollbarWidth + 'px');
}

export function remToPixels(value: number) {
    return value * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function isInViewport(element: HTMLElement) {
    var rect = element.getBoundingClientRect();
    var html = document.documentElement;
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || html.clientHeight) &&
        rect.right <= (window.innerWidth || html.clientWidth)
    );
}

export const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}
