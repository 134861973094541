<template>
    <div class="designing-screen">
        <div class="screen-content">
            <div class="controls-upper">
                <div class="hstack justify-content-between gap-4 px-4 flex-grow-1">
                    <FadeInOut mode="out-in" :duration="200">
                        <div v-if="activeStep === 1" class="hstack justify-content-start gap-4 flex-grow-1">
                            <button class="btn btn-none p-0" @click="backToPreviousStep">
                                <Icon symbol="chevron-left"></Icon>
                            </button>
                            <p class="heading-s mb-0">Pilihan Templat</p>
                        </div>
                        <div v-if="activeStep === 2" class="hstack justify-content-start gap-4 flex-grow-1">
                            <button class="btn btn-none p-0" @click="backToPreviousStep">
                                <Icon symbol="chevron-left"></Icon>
                            </button>
                            <p v-if="!selectedMediaType" class="heading-s mb-0">Format Pilihan Kad e-Ucapan</p>
                            <p v-else class="heading-s mb-0">{{ selectedMediaType === 'video' ? 'Video' : 'Gambar' }} Kad e-Ucapan</p>
                        </div>
                        <div v-if="activeStep === 3" class="hstack justify-content-start gap-4 flex-grow-1">
                            <button v-if="!selectedMediaType" class="btn btn-none p-0" @click="backToPreviousStep">
                                <Icon symbol="chevron-left"></Icon>
                            </button>
                            <p class="heading-s mb-0">{{ selectedMediaType === 'video' ? 'Video' : 'Gambar' }} Kad e-Ucapan</p>
                        </div>
                        <div v-if="activeStep === 4" class="hstack justify-content-start gap-4 flex-grow-1">
                            <button class="btn btn-none p-0" @click="backToPreviousStep">
                                <Icon symbol="chevron-left"></Icon>
                            </button>
                            <p class="heading-s mb-0">Reka Bentuk Kad e-Ucapan</p>
                        </div>
                        <div v-if="activeStep === 5" class="hstack justify-content-start gap-4 flex-grow-1">
                            <button class="btn btn-none p-0" @click="backToPreviousStep">
                                <Icon symbol="chevron-left"></Icon>
                            </button>
                            <p class="heading-s mb-0">Reka Bentuk Kad e-Ucapan</p>
                        </div>
                    </FadeInOut>
                </div>
            </div>
            <div ref="canvasContainerRef" class="canvas-content position-relative">
                <FabricCanvas></FabricCanvas>
                <div class="position-absolute bottom-0 start-0 hstack justify-content-end gap-2 p-4 py-3">
                    <button @click="scrollTo('top')" class="btn btn-none p-1 bg-primary rounded-circle">
                        <Icon symbol="chevron-up" size="m"></Icon>
                    </button>
                    <button @click="scrollTo('bottom')" class="btn btn-none p-1 bg-primary rounded-circle">
                        <Icon symbol="chevron-down" size="m"></Icon>
                    </button>
                </div>
            </div>
            <div class="controls-bottom">
                <!-- <FadeInOut mode="out-in" :duration="300"> -->
                <DesignAddBackground v-if="activeStep === 1" @next="nextStepHandler" :assets="assets">
                </DesignAddBackground>
                <DesignAddMedia v-if="activeStep === 2" @next="nextStepHandler">
                </DesignAddMedia>
                <DesignAddMediaPreview v-if="activeStep === 3" @next="nextStepHandler" @back="backToPreviousStep">
                </DesignAddMediaPreview>
                <DesignAddText v-if="activeStep === 4" @next="nextStepHandler">
                </DesignAddText>
                <DesignStaticAddText v-if="activeStep === 5" @generate-preview="createPreview"></DesignStaticAddText>
                <!-- </FadeInOut> -->
            </div>
        </div>

        <div class="debug d-none">
            <div class="overflow-hidden">
                <video ref="recordStreamCamRef" id="record-stream-cam" :width="postCardDimensionSettings.width"
                    :height="postCardDimensionSettings.height" controls playsinline></video>
            </div>
            <audio ref="audioRayaTheme1Ref" id="audio" src="/mp3/music-one.mp3" controls playsinline></audio>
            <audio ref="audioRayaTheme2Ref" id="audio" src="/mp3/music-two.mp3" controls playsinline></audio>
        </div>

        <Teleport to="#teleportModals">
            <VueRecaptcha v-if="!appConstants.GRECAPTCHA_DISABLED"
            ref="recaptchaRef" class="visibility-hidden"
                :sitekey="appConstants.GRECAPTCHA_SITE_KEY" :size="'invisible'" @verify="onVerify" badge="bottomleft">
            </VueRecaptcha>
        </Teleport>

    </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { FadeInOut } from "vue3-transitions";
import DesignAddBackground from './design-add-background.vue';
import DesignAddMedia from './design-add-media.vue';
import DesignAddMediaPreview from './design-add-media-preview.vue';
import DesignAddText from './design-add-text.vue';
import FabricCanvas from '../fabric-canvas.vue';
import { Icon } from '@/modules/core/components';
import { useDesignStore, usePreviewStore } from '../../stores';
import { storeToRefs } from 'pinia';
import useFabricCanvas from '../../composables/use-fabric-canvas';
import { fabric } from "fabric";
import appConstants from '@/modules/website/app.constants';
import { VueRecaptcha } from 'vue-recaptcha';
import { scrollIntoView } from "seamless-scroll-polyfill";
import DesignStaticAddText from './design-static-add-text.vue';


const emits = defineEmits(['back', 'continue']);
defineProps({
    assets: {
        type: Array<fabric.Sprite>,
        required: true,
    }
})

const activeStep = ref(1);
const canvasContainerRef = ref<HTMLElement>();
const previewStore = usePreviewStore();
const { recordStreamCamRef, audioRayaTheme1Ref, audioRayaTheme2Ref, videoFileType, selectedMediaType, isRecordingWebCam } = storeToRefs(previewStore);


const designStore = useDesignStore();
const { selectedTheme, isBusy, postCardDimensionSettings } = storeToRefs(designStore);
const recaptchaRef = ref<InstanceType<typeof VueRecaptcha>>();

const nextStepHandler = async () => {

    if (activeStep.value === 4) {
        return createPreview();
    }

    if (selectedTheme.value === 1) {
        return activeStep.value = 5;
    }

    ++activeStep.value;
}

const backToPreviousStep = () => {

    if (isRecordingWebCam.value) return;

    if (activeStep.value === 1) emits('back');

    const [canvas] = useFabricCanvas();

    canvas.discardActiveObject();
    canvas.renderAll();

    if (activeStep.value === 4) {
        designStore.undo();

        // design undo here doesn't seem undo the texbox we created, thus need manually remove from the object list in canvas fabric.
        designStore.removeFabricCanvasElement('textbox-element');
    } else if(activeStep.value === 2) {
        selectedMediaType.value = null;
    } else if (selectedTheme.value === 1){
        return activeStep.value = 1;
    }

    --activeStep.value;
}

const createPreview = async () => {
    if (!appConstants.GRECAPTCHA_DISABLED) {
        recaptchaRef.value?.execute()
        return;
    }

    onVerify();
}

const onVerify = async (recaptchaResponse?: string) => {
    try {
        isBusy.value = true;

        if (selectedTheme.value !== 1) {
            await previewStore.generateVideoPreviewTry2();

            if (videoFileType.value === 'webm') {
                await previewStore.transcodeVideo(recaptchaResponse);
            }
        } else {
            await previewStore.generateImagePreview();
        }

        isBusy.value = false;

        emits('continue');
    } finally {
        recaptchaRef.value?.reset();
    }
}

const scrollTo = (direction: 'top' | 'bottom') => {
    const topEl = document.querySelector('.controls-upper')!;
    const btmEl = document.querySelector('.controls-bottom')!;
    scrollIntoView(direction === 'top' ? topEl : btmEl, { behavior: 'smooth', block: 'start' })
}

onMounted(() => {
    window.scrollTo({
        top: 60,
        behavior: "smooth",
    });
})

onUnmounted(() => {
})

</script>

<style lang="scss">
.designing-screen {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-image: url('/images/design-layout.webp');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;

    .screen-content {
        z-index: 1;
    }

    .controls-upper {
        display: flex;
        height: 50px;
    }

    .controls-bottom {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    @include media-breakpoint-up(md) {
        height: 750px;
    }

}

.grecaptcha-badge {
    visibility: hidden;
    opacity: 0;
}
</style>
