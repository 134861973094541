<template>
    <div class="content mt-0">
        <div class="countdown-wrapper">
            <span class="dot"></span>
            <div id="countdown">
                <svg class="bottom">
                    <circle r="18" cx="20" cy="20"></circle>
                </svg>
                <svg ref="circleRef" class="top">
                    <circle r="18" cx="20" cy="20"></circle>
                </svg>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

const emit = defineEmits(['record']);

const props = defineProps({
    timer: {
        type: Number,
    },
})
const count = ref<any>();
const circleRef = ref<HTMLElement>();

const videoRecordingCountdown = () => {
    if (!props.timer) return;

    emit('record');
    count.value = props.timer;
    console.log(`videoRecordingCountdown(): recording count down ${count.value}`);

    const interval = window.setInterval(() => {
        if (count.value === 0) {
            circleRef.value?.classList.remove(`record-${props.timer}s`);
            return window.clearInterval(interval)
        }
        circleRef.value?.classList.add(`record-${props.timer}s`);
        count.value--;
        console.log(`videoRecordingCountdown(): recording count down ${count.value}`);
    }, 1000);
}

defineExpose({ videoRecordingCountdown });

</script>

<style lang="scss" scoped>
.countdown-wrapper {
    position: relative;
}

.dot {
    position: absolute;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    height: 55px;
    width: 55px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
}

.count-number {
    position: absolute;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    z-index: 5;
}

#countdown {
    position: relative;
    margin: auto;
    height: 40px;
    width: 40px;
    text-align: center;
    transform: scale(2);
    transform-origin: center;
}

#countdown-number {
    color: white;
    display: inline-block;
    line-height: 40px;
    z-index: 3;
}

svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    transform: rotateY(-180deg) rotateZ(-90deg);
}

svg circle {
    stroke-dasharray: 113px;
    stroke-dashoffset: 0px;
    stroke-linecap: round;
    stroke-width: 2px;
    stroke: red;
    fill: none;
}

.top circle {
    stroke: white;
}

.top.record-7s circle {
    animation: countdown 7s linear infinite forwards;
}

.top.record-10s circle {
    animation: countdown 10s linear infinite forwards;
}

@keyframes countdown {
    from {
        stroke-dashoffset: 0px;
    }

    to {
        stroke-dashoffset: 113px;
    }
}
</style>