
// import { useAuthStore } from '../store';
import axios from 'axios';
import qs from 'qs';

export default function configureAxios () {
    // const authStore = useAuthStore();

    axios.interceptors.request.use(
        function (config) {
			let headers: Record<string, string> = {};

			config.paramsSerializer = function (params) {
				return qs.stringify(params, { arrayFormat: 'comma' })
			}

            // if (authStore.token) {
			// 	headers[appConstants.AUTH_TOKEN_HTTP_HEADER_NAME] = `Bearer ${authStore.token}`;
            // }

			if (document.documentElement.lang) {
				headers['culture'] = document.documentElement.lang;
			}

			config.headers = {
				...config.headers,
				...headers
			}
            
            return config;
        }
    )

    // only when logout refresh and remove auth
	// when api return 401, remove auth
	axios.interceptors.response.use(
		response => response,
		error => {
			if (!error.response) return Promise.reject(error);
			
			if (axios.isAxiosError(error)) {
				// const whitelist = constants.AUTH_INTERCEPTOR_WHITELIST;
				// const requestUrl = error.request.responseURL;
				// if (whitelist.some(x => requestUrl.includes(x))) {
				// 	return Promise.reject(error);
				// }
			}
			
			// const { status } = error.response;
			// if (status === 401) {
			// 	const memberType = authStore.accountType;
			// 	authStore.logout()
                
			// 	if (!error.config.suppressAuthCheckReload) {
			// 		window.location.reload()
			// 	}
			// }
			return Promise.reject(error);
		}
	)
}