<template>
	<div class="vstack justify-content-start gap-3 p-3 px-4 flex-grow-1">
		<div class="option-list hstack justify-content-around gap-3">
			<img ref="defaultOptionRef" src="/images/theme/Scene-one.webp" class="btn btn-none btn-option-item"
				:class="{ 'selected': selectedOption === 1 }" @click="(e) => selectOption(1, e)" alt="">
			<img src="/images/theme/Scene-two.webp" class="btn btn-none btn-option-item"
				:class="{ 'selected': selectedOption === 2 }" @click="(e) => selectOption(2, e)" alt="">
			<img src="/images/theme/Scene-three.webp" class="btn btn-none btn-option-item"
				:class="{ 'selected': selectedOption === 3 }" @click="(e) => selectOption(3, e)" alt="">
		</div>
		<button class="btn btn-tnb-gradient w-100" @click="continueHandler">Seterusnya</button>
		<!-- <button @click="designStore.seeCanvas"> canvas</button> -->

		<Modal ref="errorModalRef" modal-size="modal-lg" id="error-modal">
			<template #modal-body>
				<div class="px-4 px-md-5 pb-5 text-center">
					<h5 class="heading-xl text-white mb-4"></h5>
					<div class="row mb-4">
						<div class="col-md-8 mb-2 mb-md-0">
							<p>Your operating system is not supported. Please upgrade your iOS version for improved
								performance, new features, and critical security
								updates.</p>
						</div>
					</div>
				</div>
			</template>
		</Modal>

	</div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { fabric } from "fabric";
import useFabricCanvas from '../../composables/use-fabric-canvas';
import { useDesignStore } from '../../stores';
import { storeToRefs } from 'pinia';
import { checkIosVersion, getMobileOperatingSystem } from '@/modules/core/utilities';
import { Modal } from '@/modules/core/components';

const emits = defineEmits(['next']);
const props = defineProps({
	assets: {
		type: Array<fabric.Sprite>,
		required: true,
	}
})

const designStore = useDesignStore();
const { selectedTheme, isBusy } = storeToRefs(designStore);

const selectedOption = ref();
const defaultOptionRef = ref<HTMLElement>();
const errorModalRef = ref<InstanceType<typeof Modal>>();


const selectOption = async (item: number, e: Event) => {
	if (isBusy.value) return;
	if (selectedOption.value == item) return;

	let start = Date.now();
	isBusy.value = true;
	selectedOption.value = item;
	selectedTheme.value = item;
	const [canvas] = useFabricCanvas();
	const target = e.target as HTMLImageElement;
	const imgEl = target.cloneNode(true) as HTMLImageElement;

	imgEl.onload = async () => {
		const imgInstance = new fabric.Image(imgEl, {
			name: 'item-bg-element',
			scaleX: designStore.postCardDimensionSettings.width / imgEl.width,
			scaleY: designStore.postCardDimensionSettings.height / imgEl.height,
			selectable: false,
		});

		canvas.clear();
		canvas.setBackgroundColor('#808080', () => { });
		canvas.add(imgInstance);
		await loadSceneSprite();
		canvas.renderAll();
		isBusy.value = false
		let timeTaken = Date.now() - start;
		console.log("selectOption(): Total time taken : " + timeTaken + " milliseconds to load generate Scene " + selectedTheme.value + ".");
	}
}

const continueHandler = () => {

	const os = getMobileOperatingSystem();
	const osVersion = checkIosVersion();

	console.log('os', os);
	console.log('osVersion', osVersion);

	if (os === 'iOS' && osVersion < 14.5 && (selectedOption.value === 2 || selectedOption.value === 3)) {
		if (!errorModalRef.value) return;
		errorModalRef.value.show();
		return;
	};

	emits('next');
}

const loadSceneSprite = async () => {

	const [canvas] = useFabricCanvas();

	// after optimizee v2
	const sceneTwoEelement = [
		{
			"id": "moon-start-center",
			"name": "G2_moon_star",
			"top": -4.68,
			"left": 41.81,
			"scale": .64,
			"flipX": false,
		},
		{
			"id": "ketupat-left",
			"name": "G2_ketupat",
			"top": -0.64,
			"left": 1.45,
			"scale": 0.8,
			"flipX": false
		},
		{
			"id": "star-left",
			"name": "G2_star",
			"top": -1.15,
			"left": 21.39,
			"scale": 0.4,
			"flipX": false
		},
		{
			"id": "ketupat-right",
			"name": "G2_ketupat",
			"top": -0.64,
			"left": 76.68,
			"scale": .8,
			"flipX": true,
		},
		{
			"id": "star-right",
			"name": "G2_star",
			"top": -1.15,
			"left": 68.59,
			"scale": 0.4,
			"flipX": false,
		},
	];

	const sceneThreeEelement = [
		{
			"id": "ketupat-short-left",
			"name": "G3_ketupat",
			"top": 9.16,
			"left": 13.48,
			"scale": 0.64,
			"flipX": false
		},
		{
			"id": "ketupat-long-left",
			"name": "G3_ketupat2",
			"top": -8.68,
			"left": 2.48,
			"scale": 0.88,
			"flipX": false
		},
		{
			"id": "star-left",
			"name": "G3_star",
			"top": 22.72,
			"left": 3.21,
			"scale": 0.24,
			"flipX": false
		},
		{
			"id": "ketupat-short-right",
			"name": "G3_ketupat",
			"top": 9.16,
			"left": 69.55,
			"scale": 0.64,
			"flipX": false
		},
		{
			"id": "ketupat-long-right",
			"name": "G3_ketupat2",
			"top": -8.68,
			"left": 74.22,
			"scale": 0.88,
			"flipX": false
		},
		{
			"id": "star-right",
			"name": "G3_star",
			"top": 22.72,
			"left": 87.99,
			"scale": 0.24,
			"flipX": false
		},
	];

	// after optimize
	// const sceneTwoEelement = [
	// 	{
	// 		"id": "moon-start-center",
	// 		"name": "G2_moon_star",
	// 		"top": -4.68,
	// 		"left": 41.81,
	// 		"scale": .32,
	// 		"flipX": false,
	// 	},
	// 	{
	// 		"id": "ketupat-left",
	// 		"name": "G2_ketupat",
	// 		"top": -0.64,
	// 		"left": 1.45,
	// 		"scale": 0.4,
	// 		"flipX": false
	// 	},
	// 	{
	// 		"id": "star-left",
	// 		"name": "G2_star",
	// 		"top": -1.15,
	// 		"left": 21.39,
	// 		"scale": 0.2,
	// 		"flipX": false
	// 	},
	// 	{
	// 		"id": "ketupat-right",
	// 		"name": "G2_ketupat",
	// 		"top": -0.64,
	// 		"left": 76.68,
	// 		"scale": 0.4,
	// 		"flipX": true,
	// 	},
	// 	{
	// 		"id": "star-right",
	// 		"name": "G2_star",
	// 		"top": -1.15,
	// 		"left": 68.59,
	// 		"scale": 0.2,
	// 		"flipX": false,
	// 	},
	// ];

	// const sceneThreeEelement = [
	// 	{
	// 		"id": "ketupat-short-left",
	// 		"name": "G3_ketupat",
	// 		"top": 9.16,
	// 		"left": 13.48,
	// 		"scale": 0.32,
	// 		"flipX": false
	// 	},
	// 	{
	// 		"id": "ketupat-long-left",
	// 		"name": "G3_ketupat2",
	// 		"top": -8.68,
	// 		"left": 2.48,
	// 		"scale": 0.44,
	// 		"flipX": false
	// 	},
	// 	{
	// 		"id": "star-left",
	// 		"name": "G3_star",
	// 		"top": 22.72,
	// 		"left": 3.21,
	// 		"scale": 0.12,
	// 		"flipX": false
	// 	},
	// 	{
	// 		"id": "ketupat-short-right",
	// 		"name": "G3_ketupat",
	// 		"top": 9.16,
	// 		"left": 69.55,
	// 		"scale": 0.32,
	// 		"flipX": false
	// 	},
	// 	{
	// 		"id": "ketupat-long-right",
	// 		"name": "G3_ketupat2",
	// 		"top": -8.68,
	// 		"left": 74.22,
	// 		"scale": 0.44,
	// 		"flipX": false
	// 	},
	// 	{
	// 		"id": "star-right",
	// 		"name": "G3_star",
	// 		"top": 22.72,
	// 		"left": 87.99,
	// 		"scale": 0.12,
	// 		"flipX": false
	// 	},
	// ];

	let currentScene: null | Array<any> = null;

	if (selectedTheme.value === 2) {
		currentScene = sceneTwoEelement;
	} else if (selectedTheme.value === 3) {
		currentScene = sceneThreeEelement;
	}

	if (!currentScene) return;

	currentScene.forEach((scene) => {

		let sprite = props.assets.find(assets => {
			if (assets.name === scene.name) {
				return assets;
			}
		});

		if (!sprite) return;

		const tempSprite = Object.assign(Object.create(Object.getPrototypeOf(sprite)), sprite);

		tempSprite.set({
			top: scene.top * designStore.postCardDimensionSettings.height / 100,
			left: scene.left * designStore.postCardDimensionSettings.width / 100,
			name: scene.id,
			selectable: false,
			scaleX: scene.scale ?? 1,
			scaleY: scene.scale ?? 1,
			flipX: scene.flipX ?? false,
		})

		// @ts-ignore
		canvas.add(tempSprite);
		tempSprite.set('dirty', true);
		tempSprite.play();

	})
}

fabric.util.requestAnimFrame(function render() {
	const [canvas] = useFabricCanvas();
	canvas.renderAll();
	fabric.util.requestAnimFrame(render);
});

onMounted(async () => {
	defaultOptionRef.value?.click();
});

</script>

<style lang="scss" scoped>
.btn.btn-option-item {
	border-radius: .5rem;
	border-width: 2px;
	overflow: hidden;
	padding: 0;
	border: solid 2px rgb($white, .3);
	background: radial-gradient(126.08% 58.3% at 52.64% 32.5%, #04814C 0%, #0C3247 100%);
	width: 70px;
	height: 70px;
	object-fit: cover;
	transition: border-color 0.2s ease-in-out 0s;

	&.selected {

		border: solid 2px $primary;
	}
}
</style>