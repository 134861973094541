import { fabric } from 'fabric';


fabric.Sprite = fabric.util.createClass(fabric.Image, {

    type: 'sprite',
    spriteIndex: 0,
    frameTime: 100,

    initialize: function (element: any, options: any) {
        // console.log(element, options)
        options || (options = {});

        const { height, width } = options;

        this.spriteWidth = width;
        this.spriteHeight = height;
        this.column = 0;
        this.row = 0;

        this.callSuper('initialize', element, options);
    },
    _render: function (ctx: any) {
        ctx.drawImage(this._element, this.column * this.spriteWidth, this.row * this.spriteHeight, this.spriteWidth, this.spriteHeight, -this.spriteWidth / 2, -this.spriteHeight / 2, this.spriteWidth, this.spriteHeight)
    },
    play: function () {
        var _this = this;
        let direction = "forward";

        let row = Math.round(_this._element.height / _this.spriteHeight - 1)
        let column = Math.round(_this._element.width / _this.spriteWidth - 1);
        _this.onPlay && _this.onPlay(),
            _this.dirty = !0,
            this.animInterval = setInterval(function () {
                direction === "forward" && (_this.column === column ? _this.row === row ? direction = "reverse" : (_this.row += 1,
                    _this.column = 0) : _this.column += 1),
                    direction === "reverse" && (_this.column === 0 ? _this.row === 0 ? direction = "forward" : (_this.row -= 1,
                        _this.column = column) : _this.column -= 1)
            }, this.frameTime)
    },
    stop: function () {
        clearInterval(this.animInterval);
    }
});

fabric.Sprite.fromURL = function (url: any, callback: any, imgOptions: any) {
    fabric.util.loadImage(url, function (img) {
        callback(new fabric.Sprite(img, imgOptions));
    });
};

fabric.Sprite.fromURLAsync = async (url, imgOptions) => {
    return new Promise<fabric.Sprite>((resolve, reject) => {
        fabric.util.loadImage(url, function (img) {
            resolve(new fabric.Sprite(img, imgOptions))
        });
    })
}

fabric.Sprite.async = true;