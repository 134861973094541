import { v4 as uuid } from 'uuid';

export function canAcceptFile(file: File, acceptAttributeValue: string, maxFileSize?: number) {
    const fileExtension = file.name.split('.').reverse()[0];
    const acceptedTypes = acceptAttributeValue.split(',').map(x => x.trim().toLowerCase());
    const isWithinSize = maxFileSize ? (file.size <= maxFileSize) : true;
    const isAcceptedType = acceptedTypes.includes('.' + fileExtension.toLowerCase());
    return {
        fileName: file.name,
        isValid: isWithinSize && isAcceptedType,
        reasons: [
            !isWithinSize ? `exceeds the maximum allowed file size of ${formatBytes(maxFileSize || 0)} by ${formatBytes(file.size - (maxFileSize || 0))}` : '',
            !isAcceptedType ? 'not valid file type' : ''
        ].filter(Boolean)
    };
}

export function urltoFile(url: string, filename: string, mimeType: string) {
    return (fetch(url)
        .then(function (res) { return res.arrayBuffer(); })
        .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
}

export function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return (
        parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    );
}

export function downFile(fileStr: any, fileType: any) {
    const anchorEl = document.createElement('a')
    anchorEl.href = fileStr
    anchorEl.download = `${uuid()}.${fileType}`
    document.body.appendChild(anchorEl) // required for firefox
    anchorEl.click()
    anchorEl.remove()
}