
<template>
    <FadeInOut mode="out-in" :duration="200">
        <LandingPage v-if="currentStep === 1" @continue="nextStepHandler">
        </LandingPage>
        <DesignPage v-if="currentStep === 2" @continue="nextStepHandler" @back="backtoHomeHandler"
            :assets="sceneSpriteList">
        </DesignPage>
        <PreviewPage v-if="currentStep === 3" @back="backtoHomeHandler">
        </PreviewPage>
    </FadeInOut>

    <div v-if="isBusy" class="spinner-overlay start-0 top-0">
        <Spinner width="64" height="64"></Spinner>
    </div>

    <div v-if="isCountingDown" class="spinner-overlay start-0 top-0">
        <h3 class="display-xl">{{ countDown }}</h3>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from 'vue';
import { FadeInOut } from "vue3-transitions";
import DesignPage from './design/design-page.vue';
import LandingPage from './landing/landing-page.vue';
import PreviewPage from './preview/preview-page.vue';
import { Spinner } from '@/modules/core/components';
import { storeToRefs } from 'pinia';
import { useDesignStore } from '../stores';
import { fabric } from "fabric";

const designStore = useDesignStore();
const { isBusy, countDown, isCountingDown } = storeToRefs(designStore);
const sceneSpriteList: Array<fabric.Sprite> = [];

const currentStep = ref(1);

const nextStepHandler = () => {
    ++currentStep.value;
}

const backtoHomeHandler = () => {
    currentStep.value = 1;
}

const loadFabricAssets = async () => {
    let start = Date.now();

    // after optimizeV2
    const sceneElementAssets = [
        {
            "name": "G2_ketupat",
            "width": 116.5,
            "height": 233,
        },
        {
            "name": "G2_moon_star",
            "width": 116.5,
            "height": 166,
        },
        {
            "name": "G2_star",
            "width": 116.5,
            "height": 233,
        },
        {
            "name": "G3_ketupat",
            "width": 116.5,
            "height": 200,
        },
        {
            "name": "G3_ketupat2",
            "width": 116.5,
            "height": 450,
        },
        {
            "name": "G3_star",
            "width": 150,
            "height": 150,
        },
    ]

    // after optimize 
    // const sceneElementAssets = [
    //     {
    //         "name": "G2_ketupat",
    //         "width": 233,
    //         "height": 467.5,
    //     },
    //     {
    //         "name": "G2_moon_star",
    //         "width": 233,
    //         "height": 332,
    //     },
    //     {
    //         "name": "G2_star",
    //         "width": 233,
    //         "height": 467.5,
    //     },
    //     {
    //         "name": "G3_ketupat",
    //         "width": 233,
    //         "height": 401,
    //     },
    //     {
    //         "name": "G3_ketupat2",
    //         "width": 233,
    //         "height": 900,
    //     },
    //     {
    //         "name": "G3_star",
    //         "width": 300,
    //         "height": 300,
    //     },
    // ]

    isBusy.value = true;

    for await (let item of sceneElementAssets) {
        const sprite = await fabric.Sprite.fromURLAsync(`/images/element/optimize_v2/${item.name}.webp`, item);
        // after optimize
        // const sprite = await fabric.Sprite.fromURLAsync(`/images/element/optimize/${item.name}.webp`, item);
        sceneSpriteList.push(sprite);
    }

    isBusy.value = false;
    let timeTaken = Date.now() - start;
    console.log("loadFabricAssets(): Total time taken : " + timeTaken + " milliseconds to load " + sceneElementAssets.length + " elements.");
}

onMounted(() => {
    loadFabricAssets();
});


watchEffect(() => {
    if (isBusy.value) {
        const element = document.getElementsByClassName('designing-screen')[0] as HTMLElement;
        const height = (element && element.offsetHeight) ?? 0;
        document.documentElement.style.setProperty('--content-min-full-height', height + 'px');
        return document.body.style.overflowY = "hidden";
    }

    const contentMinFullHeight = (window.innerHeight ?? 0);
    document.documentElement.style.setProperty('--content-min-full-height', contentMinFullHeight + 'px');
    return document.body.style.overflowY = "auto";
})

</script>

<style lang="scss">
video {
    width: 100%;
    height: auto;
}
</style>